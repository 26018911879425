<template>
  <div>
    <!-- offcanvas Repeaters -->
    <div
      id="offcanvasRepeaters"
      class="offcanvas offcanvas-end offcanvas-end--big-panel d-flex"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasRepeatersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasRepeatersLabel"
          class="offcanvas-title"
        >
          <small>{{ convoTypeTitle }}</small><br><strong>View repeaters in {{ convoTypeTitle }}</strong>
        </h3>
        <button
          type="button"
          class="btn-close text-reset btn btn-icon btn-icon rounded-circle btn-secondary"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <h4>Search researcher</h4>
        <div class="row d-flex alig-items-end">
          <div class="col-sm-4">
            <label
              for=""
              class="form-label"
            >Name</label>
            <v-select
              v-if="aspirants.length !== undefined"
              v-model="aspirant"
              label="name"
              :options="aspirants"
              :get-option-key="option => option.id"
              @search="onSearch({ name: $event })"
            >
              <option
                value=""
                selected
              >
                All areas
              </option>
            </v-select>
          </div>
          <div class="col-sm-8">
            <div class="d-flex align-items-end h-100">
              <button class="btn btn-dark me-1">
                <i data-feather="search" />
              </button>
              <button class="btn btn-outline-dark">
                <i data-feather="refresh-ccw" />
              </button>
            </div>
          </div>
        </div>

        <div
          v-if="aspirant"
          class="card shadow-none bg-transparent border-primary repeater--miniProfile"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-sm-4">
                <div class="d-flex align-items-center">
                  <div class="avatar me-50">
                    <img
                      :src="require('@/assets/images/portrait/small/avatar-s-11.jpg')"
                      alt="Avatar"
                      width="38"
                      height="38"
                    >
                  </div>
                  <div class="more-info">
                    <h6 class="mb-0">
                      {{ aspirant.name }}
                    </h6>
                    <p class="mb-0">
                      ICFO, Quantum Information Theory
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <p>Email<strong>{{ aspirant.email }}<a
                  :href="`mailto:${aspirant.email}`"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Open and send a email"
                ><i data-feather="mail" /></a></strong></p>
              </div>
              <div class="col-sm-3">
                <p>Phone<strong>{{ aspirant.phone }}</strong></p>
              </div>
              <div class="col-sm-2">
                <a
                  href=""
                  class="btn btn-dark btn-sm w-100"
                ><i data-feather="send" /> Send notification</a>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="aspirant"
          class="table-responsive-sm mt-3"
        >
          <div class="row mb-1">
            <div class="col-sm-8 d-flex align-items-center">
              <h4><strong>{{ calls.length }} </strong>calls</h4>
            </div>
            <div class="col-sm-4 d-flex justify-content-end">
              <a
                href="#"
                class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Export to Excel"
              ><i data-feather="file" /></a>
            </div>
          </div>

          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Call</th>
                <th>Area</th>
                <th>AVG Grade</th>
                <th>Status</th>
                <th class="text-end">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="call in calls"
                :key="call.id"
              >
                <td>{{ call.title }}</td>
                <td><a
                  href="senior-call-admin.php"
                  class="badge bg-light-secondary"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Go to Senior Call 2021"
                >{{ call.code }}</a>
                </td>
                <td>
                  <template v-for="area, index in call.areas">
                    {{ area.code + (index < (call.areas.length - 1) ? ', ' : ' ') }}
                  </template>
                </td>
                <td>7,58</td>
                <td><span class="badge badge-light-success">Offered to position accepted</span></td>
                <td class="text-end">
                  <a
                    href="#"
                    class="btn btn-icon btn-icon rounded-circle btn-flat-dark me-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Go to profile"
                  ><i data-feather="eye" /></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      name: null,
      aspirant: null,
      calls: {},
      convoTypeTitle: 'Senior call',
      convoType: 'senior',
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/convoRepeatersView',
      aspirants: 'users/fakeUsers',
    }),
  },
  watch: {
    // whenever question changes, this function will run
    async aspirant() {
      await this.$store.dispatch('convo/filter', { candidate: this.aspirant.name, type: this.convoType })
      this.calls = this.$store.getters['convo/convos']
      feather.replace({
        width: 14,
        height: 14,
      })
    },
  },
  async mounted() {
    // if (this.aspirants.length === undefined) {
    //   await this.$store.dispatch('users/fetchAspirants')
    // }

    const route = this.$route.name
    if (route.includes('academy')) {
      this.convoTypeTitle = 'Academy call'
      this.convoType = 'academy'
    } else if (route.includes('promotion')) {
      this.convoTypeTitle = 'Promotion call'
      this.convoType = 'promotion'
    }

    feather.replace({
      width: 14,
      height: 14,
    })
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleConvoRepeatersView', false)
    },
    async onSearch(search) {
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch('users/fetchFakeUsers', { name: search.name })
      }
    },
  },
}
</script>
